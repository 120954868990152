import React, { createContext, useEffect, useReducer, useState } from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import themes from '@iso/config/theme/theme.config';
import AppLocale from '@iso/config/translation';
import { resolveRequest } from "@apis";
import { createNestedIdArrayMap, getTokenData } from './utils/helpers';
import { userManagementApi } from './config/apiUM';
import useCustomLocalTranslation from './hooks/useCustomLocalTranslation';
import { permissionConstants } from './utils/constants';
import useAppTheme from './hooks/useAppTheme';
import Favicon from "react-favicon";
import { Helmet } from 'react-helmet';

export const AppContext = createContext();

const getTranslatedDashboardConfig = (clt) => {
  //Todo: change enableFutureDates and allowFutureDates name as it is causing confusion
  return [
    {
      title: "Incidents",
      path: `dashboards/incidents`,
      tabs: [
        {
          label: "Summary",
          value: "summary",
          url: "/dashboards/incidents/summary",
          enableDrillDown: true,
          enableFutureDates: false,
          drillDownAPI: "/dashboards/incidents/summary/drilldown",
          drillDownRedirect: '/incidents/raise-incident/execute/{{id}}',
        },
        {
          label: "Monthly Trends",
          value: "monthlyTrends",
          url: "/dashboards/incidents/monthly-trends",
          enableDrillDown: true,
          enableFutureDates: false,
          drillDownAPI: "/dashboards/incidents/monthly-trends/drilldown",
          drillDownRedirect: '/incidents/raise-incident/execute/{{id}}',
        },
        {
          label: "Comparisons",
          value: "comparisons",
          url: "/dashboards/incidents/comparisons",
          enableDrillDown: true,
          enableFutureDates: false,
          drillDownAPI: "/dashboards/incidents/comparisons/drilldown",
          drillDownRedirect: '/incidents/raise-incident/execute/{{id}}',
        },
        {
          label: "Rates",
          value: "incidentRates",
          url: "/dashboards/incidents/incident-rates",
          enableDrillDown: false,
          enableFutureDates: false,
          drillDownAPI: "/dashboards/incidents/incident-rates/drilldown",
          drillDownRedirect: '/incidents/raise-incident/execute/{{id}}',
          showTabConfigKey: 'freq_rate_subscription'
        },
      ],
      requiredPermissions: [permissionConstants["Incidents_View Incident Analysis"]]
    },
    {
      title: "Issues",
      path: `dashboards/issues`,
      tabs: [
        {
          label: "Summary",
          value: "summary",
          url: "/dashboards/issues/summary",
          enableDrillDown: true,
          enableFutureDates: false,
          drillDownAPI: "/dashboards/issues/summary/drilldown",
          drillDownRedirect: '/issues/raise-issue/execute/{{id}}',
        },
        {
          label: "Monthly Trends",
          value: "monthlyTrends",
          url: "/dashboards/issues/monthly-trends",
          enableDrillDown: true,
          enableFutureDates: false,
          drillDownAPI: "/dashboards/issues/monthly-trends/drilldown",
          drillDownRedirect: '/issues/raise-issue/execute/{{id}}',
        },
        {
          label: "Comparisons",
          value: "comparisons",
          url: "/dashboards/issues/comparisons",
          enableDrillDown: true,
          enableFutureDates: false,
          drillDownAPI: "/dashboards/issues/comparisons/drilldown",
          drillDownRedirect: '/issues/raise-issue/execute/{{id}}',
        },
      ],
      requiredPermissions: [permissionConstants["Issues_View Issue Analysis"]]
    },
    {
      title: "Risk Assessment",
      path: `dashboards/risk-assessments`,
      tabs: [
        {
          label: "Summary",
          value: "summary",
          url: "/dashboards/risk-assessments/summary",
          enableDrillDown: true,
          drillDownAPI: "/dashboards/risk-assessments/summary/drilldown",
          drillDownRedirect: '/risk-assessments/execute/{{id}}',
          enableFutureDates: false,
          allowFutureDates: true
        },
        {
          label: "Tasks Raised",
          value: "actionRaised",
          url: "/dashboards/risk-assessments/action-raised",
          enableDrillDown: true,
          drillDownAPI: "/dashboards/risk-assessments/action-raised/drilldown",
          drillDownRedirect: '/task/{{id}}',
          enableFutureDates: false,
          allowFutureDates: true
        },
        {
          label: "Activity Log",
          value: "activityLog",
          url: "/dashboards/risk-assessments/activity-log",
          enableDrillDown: true,
          drillDownAPI: "/dashboards/risk-assessments/activity-log/drilldown",
          drillDownRedirect: '/risk-assessments/execute/{{id}}',
          enableFutureDates: false,
          allowFutureDates: true
        },
      ],
      requiredPermissions: [permissionConstants["RiskAssessment_View All Analysis"]]
    },
    {
      title: "Inspections",
      path: `dashboards/inspections`,
      tabs: [
        {
          label: "Summary",
          value: "summary",
          url: "/dashboards/inspections/summary",
          enableDrillDown: true,
          enableFutureDates: false,
          drillDownAPI: "/dashboards/inspections/summary/drilldown",
          drillDownRedirect: '/inspections/execute/{{id}}',
        },
        {
          label: "Scores",
          value: "scores",
          url: "/dashboards/inspections/scores",
          enableDrillDown: true,
          enableFutureDates: false,
          drillDownAPI: "/dashboards/inspections/scores/drilldown",
          drillDownRedirect: '/inspections/execute/{{id}}',
        },
        {
          label: "Negative Responses",
          value: "negativeResponses",
          url: "/dashboards/inspections/negative-responses",
          enableDrillDown: true,
          enableFutureDates: false,
          drillDownAPI: "/dashboards/inspections/negative-responses/drilldown",
          drillDownRedirect: '/inspections/execute/{{id}}',
        },
        {
          label: "Tasks Raised",
          value: "tasksRaised",
          url: "/dashboards/inspections/tasks-raised",
          enableDrillDown: true,
          enableFutureDates: false,
          drillDownAPI: "/dashboards/inspections/tasks-raised/drilldown",
          drillDownRedirect: '/task/{{id}}',
        },
        {
          label: "Pending Tasks",
          value: "actionLog",
          url: "/dashboards/inspections/action-log",
          enableDrillDown: true,
          enableFutureDates: false,
          drillDownAPI: "/dashboards/inspections/action-log/drilldown",
          drillDownRedirect: '/task/{{id}}',
        },
      ],
      requiredPermissions: [permissionConstants["Inspections_View Inspection Analysis"]]
    },
    {
      title: "Tasks",
      path: `dashboards/tasks`,
      tabs: [
        {
          label: "Summary",
          value: "summary",
          url: "/dashboards/tasks/summary",
          enableDrillDown: true,
          enableFutureDates: false,
          drillDownAPI: "/dashboards/tasks/summary/drilldown",
          drillDownRedirect: '/task/{{id}}',
        },
        {
          label: "Compliance",
          value: "compliance",
          url: "/dashboards/tasks/compliance",
          enableDrillDown: true,
          enableFutureDates: false,
          drillDownAPI: "/dashboards/tasks/compliance/drilldown",
          drillDownRedirect: '/task/{{id}}',
        },
      ]
    }
    // {
    //   path: "dashboards/hse-plan-compliance",
    //   key: "hse-plan-compliance",
    //   title: "HSE Plan Compliance",
    //   requiredPermissions: [
    //     permissionConstants["HSE_Plan View Analysis"]
    //   ],
    // }
  ];
}

export default function AppProvider({ children }) {
  const { locale } = useSelector(state => state.LanguageSwitcher.language);
  const { themeName } = useSelector(state => state.ThemeSwitcher.changeThemes);
  const currentAppLocale = AppLocale[locale];

  const [appState, setAppState] = useReducer((prev, next) => ({ ...prev, ...next }), {});
  const [orgDropdownOptions, setOrgDropdownOptions] = useState({});

  const clt = useCustomLocalTranslation();
  const [dashboardConfig, setDashboardConfig] = useState([]);
  const [isDashboardsConsolidated, setIsDashboardsConsolidated] = useState(true);

  const appTheme = useAppTheme();

  useEffect(() => {
    if (clt("dashboard_menu") !== 'dashboard_menu') {
      setIsDashboardsConsolidated(clt("dashboard_menu") === "consolidated")
    }
  }, [clt("dashboard_menu")]);

  const _setCSSVars = (theme = {}) => {
    const { primary, secondary, tertiary } = theme.colors || {};

    const rootEl = document.querySelector(":root");

    if (!rootEl) return;

    if (primary) rootEl.style.setProperty("--primary-color", primary);
    if (secondary) rootEl.style.setProperty("--secondary-color", secondary);
    if (tertiary) rootEl.style.setProperty("--tertiary-color", tertiary);
  }

  useEffect(() => {
    if (appTheme["tenant-theme"]) _setCSSVars(appTheme["tenant-theme"]);
  }, [appTheme["tenant-theme"]]);

  const getDashboardConfigData = () => {
    const getKeyOrPathName = (currentKey) => {
      if (isDashboardsConsolidated) return currentKey;
      else if (currentKey.includes("risk-assessment")) return "risk-assessment/dashboard";

      return `${currentKey.split("/").pop()}/dashboard`;
    }

    const data = getTranslatedDashboardConfig(clt).map(d => ({
      ...d,
      path: getKeyOrPathName(d.path)
    }));

    setDashboardConfig(data);
  }

  useEffect(() => {
    getDashboardConfigData();
  }, [clt("dashboard_menu")]);
  const [isServiceAccount, setIsServiceAccount] = useState(false);

  const fetchOrgDropdownOptions = async () => {
    const promise = userManagementApi.getFacilities();
    const { data, error } = await resolveRequest(promise);

    if (error) return;

    setOrgDropdownOptions(createNestedIdArrayMap(data));
  }

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      fetchOrgDropdownOptions();
      const { isServiceAccount } = getTokenData();

      setIsServiceAccount(isServiceAccount);
    }
  }, []);

  const shouldRedirectToSSO = (subdomain) => {
    if (process.env.REACT_APP_ENV === "development") {
      if (subdomain.includes('safety-qube')) return false
    } else if (process.env.REACT_APP_ENV === "pre-production") {
      if (subdomain.includes('staging')) return true
    } else if (process.env.REACT_APP_ENV === "production") {
      if (subdomain.includes('standrews')) return true
    }
  }

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      {appTheme?.["app-favicon"] && <Favicon url={appTheme?.["app-favicon"]} />}
      <Helmet>
        <title>{appTheme?.["app-name"]}</title>
      </Helmet>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <AppContext.Provider value={{
          appState,
          setAppState,
          orgDropdownOptions,
          fetchOrgDropdownOptions,
          isDashboardsConsolidated,
          dashboardConfig,
          isServiceAccount,
          shouldRedirectToSSO,
        }}>
          <ThemeProvider theme={themes[themeName]}>
            {children}
          </ThemeProvider>
        </AppContext.Provider>
      </IntlProvider>
    </ConfigProvider>
  );
}
