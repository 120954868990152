import React, { useEffect, useState } from "react";
import { isAndroid, isIOS, isMobile, isTablet } from "react-device-detect";
import googlePlay from "@iso/assets/images/googleplaybadge.png";
import appleStore from "@iso/assets/images/appStore.png";
import { Provider } from "react-redux";
import GlobalStyles from "@iso/assets/styles/globalStyle";
import { store } from "./redux/store";
import Boot from "./redux/boot";
import Routes from "./router";
import AppProvider from "./AppProvider";
import SidebarProvider from "./sidebarContext";
import { getTokenData } from './utils/helpers';
import "./App.css";
import Backdrop from "./components/Backdrop";
import { CustomLocalTranslationProvider } from "./context/CustomLocalTranslationContext";
import { OhlDefinitionDataProvider } from "./context/OhlDefinitionDataContext";
import { isCurrentRouteServiceRoute, isQrCodeRelatedRoute } from "./route.constants";
import { WebSocketProvider } from "./context/WebSocketContext";

//add prototype functions
URLSearchParams.prototype.toObject = function () {
  const q = new URLSearchParams(window.location.search);
  const paramsObject = {};

  q.forEach((value, key) => {
    paramsObject[key] = value;
  });

  return paramsObject;
}

const App = () => {
  const [isOffline, setIsOffline] = useState(!window.navigator.onLine);
  const search = new URLSearchParams(window.location.search);
  const utmSource = search.get("utm_source");
  const fromQrCode = utmSource === 'qr_code';

  const orientation = window.screen.orientation ? window.screen.orientation.type.split("-") : [];

  const isTabletDevice = isTablet || (isMobile && ((orientation[0] === "portrait" && window.innerWidth > 500) || (orientation[0] === "landscape" && window.innerHeight > 500)))

  const { isServiceAccount } = getTokenData();

  const isServiceRoute = isCurrentRouteServiceRoute();
  const isQrCode = isQrCodeRelatedRoute();
  
  const isMobileResolution = isMobile && !isTabletDevice;
  
  /**
   * Important
   * Service Routes are routes that are accessible with service account.
   * 
   * If QR code route. Don't do anything
   * If mobile view and its a service route, we check if the logged in account is also a service account.
   */

  const shouldRedirectToAppStore = !fromQrCode
    ? !isQrCode && isMobileResolution
    : !isQrCode &&
      isMobileResolution &&
      ((isServiceRoute && !isServiceAccount) || !isServiceRoute);

  const hostname = window.location.hostname;

  window.isSecureQube = hostname.toLowerCase().includes("orbex-security") || hostname.toLowerCase().includes("secureqube");

  useEffect(() => {
    if (shouldRedirectToAppStore) {
      if (isAndroid) {
        window.location.replace(
          `https://play.google.com/store/apps/details?id=${window.isSecureQube ? 'com.secureqube' : 'com.safetyqube'}`
        );
      } else if (isIOS) {
        window.location.replace(
          `https://apps.apple.com/lt/app/${window.isSecureQube ? 'secureqube/id6511223494' : 'safetyqube/id1576481295'}`
        );
      }
    }

    localStorage.setItem("redLoginVariable", true);

    const offlineEventListeners = () => setIsOffline(true);
    const onlineEventListeners = () => setIsOffline(false);

    window.addEventListener("offline", offlineEventListeners);
    window.addEventListener("online", onlineEventListeners);

    return () => {
      window.removeEventListener("offline", offlineEventListeners);
      window.removeEventListener("online", onlineEventListeners);
    }
  }, [shouldRedirectToAppStore]);

  const renderUI = () => {
    if (shouldRedirectToAppStore) {
      if (isAndroid) return (
        <div className="playLink">
          <img crossorigin="anonymous" src={googlePlay} className="imgElement-app" alt="tile"></img>
        </div>
      );
      else if (isIOS) return (
        <div className="playLink">
          <img crossorigin="anonymous" src={appleStore} className="imgElement-app" alt="tile"></img>
        </div>
      );
    }

    return (
      <Provider store={store}>
        <CustomLocalTranslationProvider>
          <AppProvider>
            <WebSocketProvider>
              <OhlDefinitionDataProvider>
                <SidebarProvider>
                  <GlobalStyles />
                  <Routes />
                  {isOffline && <Backdrop message="You're offline. Please connect to internet." />}
                </SidebarProvider>
              </OhlDefinitionDataProvider>
            </WebSocketProvider>
          </AppProvider>
        </CustomLocalTranslationProvider>
      </Provider>
    );
  }

  return (
    <div className="App h-100per">
      {renderUI()}
    </div>
  );
};
Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
