import Axios from "axios";
import authHeaderFn from "../../utils/authHeaderFn"
import { get } from "lodash";
import showNotification from "@utils/notification";
import redirectLogin from "../../utils/redirectHelper";
import { getSubDomain } from "@utils/helpers";

let BASE_URL = "http://35.179.56.119:8081";

if (process.env.REACT_APP_ENV === "local") {
  BASE_URL = "http://35.179.56.119:8081";
} else if (process.env.REACT_APP_ENV === "development") {
  if (['dev-sq-atnm', 'dashboard-atnm'].indexOf(getSubDomain()) > -1) {
    BASE_URL = "http://18.169.18.240:8081";
  } else {
    BASE_URL = "http://35.179.56.119:8081";
  }
} else if (process.env.REACT_APP_ENV === "pre-production") {
  BASE_URL = "https://staging.safetyqube.uk/api/users";
} else if (process.env.REACT_APP_ENV === "production") {
  BASE_URL = "https://safetyqube.uksouth.cloudapp.azure.com/squm";
}

const axiosInstance = Axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/vnd.safetyqube.com+json",
  },
});


const requestUM = {
  getAuth: (url, params, headers = {}) => {
    return axiosInstance.get(url, { params, headers });
  },
  get: (url, params, headers = {}) => {
    return axiosInstance.get(url, { params, headers: authHeaderFn(headers) });
  },
  post: (url, data, headers = {}) => {
    return axiosInstance.post(url, data, { headers: authHeaderFn(headers) });
  },
  patch: (url, data, headers = {}) => {
    return axiosInstance.patch(url, data, { headers: authHeaderFn(headers) });
  },
  put: (url, data, headers = {}) => {
    return axiosInstance.put(url, data, { headers: authHeaderFn(headers) });
  },
  delete: (url, data, headers = {}) => {
    return axiosInstance.delete(url, { data, headers: authHeaderFn(headers) });
  },
};

export const resolveRequestUM = async (requestPromise) => {
  let data = {};
  let redLoginVariable = localStorage.getItem("redLoginVariable");
  if (redLoginVariable === "true") {
    try {
      const result = await requestPromise;
      data = result.data;
      data.statusCode = result.status;
    } catch (e) {
      // console.log(e);
      const errorData = get(e, "response.data");
      const status = get(e, "response.status");
      // console.log(errorData, status);
      if (status === 500)
        showNotification("error", errorData?.message || "Action could not be performed, please retry or contact system admin");
      else if (status === 409 && typeof errorData === "object")
        showNotification("error", errorData.message, errorData.data);
      else if (status === 401 && typeof errorData === "string") {
        showNotification("error", "Session expired, please login again");
        localStorage.setItem("redLoginVariable", false);
        redirectLogin();
      }

      data = typeof errorData === "object" ? errorData : {};
      data.error = true;
      data.statusCode = status;
    }
  }

  return data;
};

export default requestUM;
